export default {
  heading: 'Comfortaa, san-serif',
  body: 'Raleway, san-serif',
  monospace: 'Menlo, monospace',
  display: 'EB Garamond, serif',
  handwriting: 'GFS Neohellenic',
  googleFonts: [
    'Comfortaa:100,200,300,400,500,600,700,800,900',
    'Raleway:100,200,300,400,500,600,700,800,900',
    'EB Garamond:400,500,600,700,800,900',
    'GFS Neohellenic'
  ],
  customFamilies: ['Clattering, cursive'],
  customUrls: ['https://gonation.biz/fonts/clattering/clattering.css']
}
