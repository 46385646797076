export default {
  primary: {
    backgroundColor: 'dark',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'dark',
    fontWeight: '500',
    padding: '1rem 2rem',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'dark'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '0.5rem',
    color: 'primary',
    border: 'none',
    borderBottom: 'solid 2px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey'
    }
  }
}
