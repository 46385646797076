export default {
  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  pageHero: {
    minHeight: '70vh',
    maxHeight: '70vh',
    '.title': {
      borderLeft: 'none',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '2px'
    }
  },

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '2',
    textTransform: 'uppercase',
    marginBottom: '1rem',
    letterSpacing: '2px',
    fontWeight: '300',
    padding: '1rem 0rem',
    width: 'fit-content',
    color: 'primary'
  },
  subtitle: {
    fontWeight: '300',
    opacity: '0.6',
    margin: '0rem 0rem 1rem',
    order: '1'
  },

  text: {
    order: '3',
    fontSize: '0.9rem',
    lineHeight: '2'
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      marginTop: ['1rem', '', '1.5rem'],
      variant: 'buttons.primary'
    }
  },

  sideBySide1: {
    minHeight: '75vh',
    '.content': {
      padding: ['2rem', '2rem', '3rem', '4rem', '5rem'],
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      '::before': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        position: 'absolute',
        left: '0.5rem',
        top: '50%'
      },
      '::after': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        position: 'absolute',
        right: '0.5rem',
        top: '50%'
      }
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      padding: '0rem 1rem'
    },
    '.gatsby-link ': {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '.container': {
      position: 'fixed',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem 1rem'],
      backgroundColor: 'background'
    },
    '.gonation': {
      display: 'none'
    },
    '.containerScrolled': {
      backgroundColor: 'background',
      '.logoLocationContainer': {
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static']
      }
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0rem auto 0rem 0rem'],
      padding: '0rem',
      position: ['', '', '', 'fixed'],
      top: '0rem',
      '.logo': {
        maxWidth: '150px',
        maxHeight: 'unset',
        backgroundColor: 'background',
        borderRadius: '500px',
        p: '1rem',
        img: {
          filter: 'unset'
        }
      },
      '.logoScrolled': {
        img: { p: '0rem', maxWidth: '50px' }
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        display: 'none',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        color: 'dark',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          backgroundColor: 'primary',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      div: {
        backgroundColor: 'light'
      },
      'div:nth-of-type(2)': {
        width: '80%'
      }
    },
    '.navMenu': {
      backgroundColor: 'light',
      transform: 'unset',
      left: '-200vw',
      top: '0rem'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],

      width: ['85%', '60%', '50%', '40%'],
      right: 'unset',
      left: '0rem',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '100px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: '#687473'
      },
      '.navItem': {
        a: {
          color: '#687473',
          fontSize: '1.25rem'
        }
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      right: '0%',
      left: 'unset',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    '.address, .phone': {
      display: 'none'
    },
    padding: ['1rem 0rem 0rem', '', '', '0rem'],
    backgroundColor: 'light',
    color: 'dark',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['', '', 'stretch', 'stretch'],
    borderTop: '1px lightgrey',
    '.gonation': {
      display: 'none'
    },
    '::after': {
      content: `"© ${new Date().getFullYear()} Telios"`,
      width: '100%',
      backgroundColor: 'black',
      color: 'light',
      padding: '1rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '400px',
      width: '100%',
      objectFit: 'contain'
    },
    '.contactDetails-container': {
      margin: ['1rem 0rem 0rem', '', '', '2rem 0rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: ['1rem', '', '', '3rem 2rem'],
      width: ['', '', '', '20%'],
      flexGrow: '1',
      justifyContent: 'flex-start',
      maxWidth: ['unset', '', '', '350px']
    },
    '.sectionHeading': {
      color: 'dark',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem'],
      fontWeight: '300'
    },

    '.contactColumn': {
      borderRight: 'none !important',
      padding: ['1rem', '', '', '3rem 2rem']
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'primary',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '3rem 3rem', '3rem 3rem'],
      width: ['100%', '', '', '40%'],
      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'light',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '400',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'brightness(0) invert(1)'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'dark'
    },

    '.aboutColumn': {
      '.aboutText': {
        color: 'dark'
      },
      '.footerAboutButton': {
        color: 'light',
        span: {
          variant: 'buttons.primary',
          fontSize: '0.9rem',
          padding: '0.75rem 1.5rem',
          height: 'auto',
          width: 'auto',
          ':hover': {
            backgroundColor: 'backgroundSecondary',
            borderColor: 'backgroundSecondary',
            color: 'dark'
          }
        }
      }
    }
  },

  ctaWidget: {
    a: { borderRadius: ['', '', '300px'] }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    marginTop: ['120px', '', '185px', '165px'],
    marginBottom: ['1rem', '', '', '2.75rem'],
    height: ['38vw', '38vw', '38vw', '38vw'],
    '.slick-slider': {
      height: ['38vw', '38vw', '38vw', '38vw'],
      '.slick-list': {
        height: ['38vw', '38vw', '38vw', '38vw'],
        '.slick-slide': {
          height: ['38vw', '38vw', '38vw', '38vw'],
          '> div': {
            height: ['38vw', '38vw', '38vw', '38vw'],
            img: {
              height: 'auto'
            }
          }
        }
      }
    },
    '.slick-initialized .slick-slide': {
      // padding: ['0rem 10vw', '', '', '0rem 20vw']
    },
    '.slick-slide img': {
      objectFit: 'contain'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageBanner: {
    height: '90vh',
    backgroundPosition: 'bottom center',
    '.section': {
      width: '95%',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxWidth: 'unset',
      margin: 'auto',
      '> div': {
        width: '100%'
      }
    },

    '.title': {
      fontSize: ['2rem', '3rem', '3.5rem', '4rem', '4.5rem'],
      fontWeight: '400',
      letterSpacing: '2px',
      border: 'none',
      padding: '0rem',
      fontFamily: 'Clattering, cursive',
      textAlign: 'center',
      m: 'auto',
      width: '100%'
    },
    '.subtitle': {
      fontSize: ['1.5rem', '2rem', '2.5rem', '3rem'],
      textTransform: 'uppercase',
      fontWeight: '400',
      letterSpacing: '2px',
      border: 'none',
      padding: '0rem',
      color: 'primary'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageTag1: {
    variant: 'customVariants.sideBySide1'
  },

  homepageShout: {
    '.title': {
      fontSize: '1.2rem'
    },
    '.date': {
      backgroundColor: 'dark'
    },
    '.text': {
      padding: '2rem',
      color: 'light',
      backgroundColor: '#144084'
    }
  },
  homepageFlavours: {
    variant: 'customVariants.sideBySide1'
  },

  homepageFind: {
    variant: 'customVariants.sideBySide1',

    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ebf2ff',
    '.content, .lazyload-wrapper': {
      width: ['100%', '100%', '100%', '100%'],
      maxWidth: '767px',
      margin: 'auto',
      paddingBottom: '0rem'
    },
    '.title': {},
    '.text': {
      variant: 'customVariants.text',

      textAlign: 'center',
      '*': {}
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: '#336cce !important'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageProductsTitle: {
    marginTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '900px',
    margin: 'auto',
    '.title': {
      fontSize: ['2rem', ' 3rem', '4rem', '4.5rem'],
      width: 'fit-content',
      borderBottom: '2px solid'
    }
  },

  homepageProduct: {
    variant: 'customVariants.sideBySide1',
    maxWidth: '900px',
    margin: ['0rem auto 3rem', '', 'auto'],
    minHeight: 'unset',
    'div.content': {
      order: ['1', '', '2']
    },
    '.lazyload-wrapper': {
      width: ['70%', '60%', '40%', '40%'],
      margin: 'auto',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
      width: 'fit-content'
    },
    '.ctaButton': {
      order: '5'
    }
  },

  homepageSocial: {
    variant: 'customVariants.sideBySide1',
    background: 'none',
    backgroundColor: 'white',
    color: 'white',
    minHeight: '50vh',
    '.iconsContainer': {
      order: '6',
      svg: {
        fill: 'dark',
        path: {
          fill: 'dark'
        }
      }
    },
    '.gonation': {
      display: 'none'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageContact: {
    variant: 'customVariants.sideBySide1',
    padding: '0rem',
    '.section': {
      backgroundColor: '#020303db',
      padding: '3rem',
      height: 'fit-content',
      m: 'auto auto auto 0rem',
      alignItems: 'flex-start',
      left: '0rem'
    },
    '.title': {
      color: 'light'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'left',

      '*': {
        color: 'white'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: '#336cce !important'
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    padding: ['', '', '3rem'],
    '.section': {
      flexDirection: ['', '', 'row'],
      maxWidth: 'unset',
      alignItems: ['flex-start', '', 'center']
    },
    '.title': {
      fontSize: ['2rem', '', '', '2.5rem', '3rem', '3.5rem'],
      lineHeight: '1',
      color: 'primary',
      width: ['', '', '30%'],
      textAlign: ['left', 'left', 'left'],
      padding: ['', '', '2rem 2rem 2rem 0rem'],
      lineheight: '1.5'
    },
    '.text': {
      width: ['', '', '70%'],
      textAlign: ['left', 'left', 'left'],
      padding: ['', '', '1rem 0rem 1rem 2rem'],
      fontSize: ['', '', '1.2rem'],
      borderLeft: ['', '', 'solid 3px'],
      borderColor: ['', '', '#b7a495']
    }
  },

  aboutDictionary: {
    padding: ['', '', '1rem 3rem 1rem 1rem'],
    '.content': {
      backgroundColor: 'primary',
      margin: '2rem',
      width: ['calc(100% - 4rem)', '', 'calc(50% - 4rem)'],
      color: 'light',
      order: ['2', '', 'unset']
    },
    '.title': {
      order: '2',
      fontSize: ['2.2rem', '', '', '3rem', '4rem'],
      '::after': {
        content: "'(noun)'",
        fontStyle: 'italic',
        fontSize: ['2rem', '', '', '2.5rem', '2.5rem'],
        marginLeft: '0.5rem'
      }
    },
    '.subtitle': {
      order: '1',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#687473'
    },
    '.text': {
      order: '3',
      color: 'light',
      paddingLeft: '1rem',
      borderLeft: '4px solid',
      borderColor: 'light',
      maxWidth: '320px'
    }
  },

  aboutPageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  productPageProduct: {
    variant: 'customVariants.sideBySide1',
    maxWidth: '1200px',
    margin: ['0rem auto 3rem', '', 'auto'],
    minHeight: 'unset',
    'div.content': {
      width: ['100%', '100%', '70%', '70%'],
      order: ['1', '', '2']
    },
    '.lazyload-wrapper': {
      width: ['70%', '60%', '30%', '30%'],
      margin: 'auto',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
      width: 'fit-content'
    },
    '.ctaButton': {
      order: '5'
    }
  },

  menu: {
    border: 'solid 1px black',
    padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem', '3rem 1rem', '3rem 1rem'],
    margin: ['2rem auto', '2rem auto', '2rem auto', '3rem auto', '3rem auto'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'light',
    '.innerMenuContainer': {
      // padding: ['1rem', '1rem', '1rem', '1rem'],
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem',
      paddingTop: '0rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      margin: '0rem 0rem 2rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem'],
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '::before': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 1rem'
      },
      '::after': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 1rem'
      }
    },
    '.menuSectionDescription': {
      margin: '0rem auto 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px'
    },
    '.menuItemName': {
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontSize: ['1rem', '', '1.25rem'],
      color: '#687473'
    },
    '.menuItemDescription': {
      color: '#b7a495'
    },
    '.menuPriceContainer': {
      display: 'none'
    },
    '.variantsContainer': {
      display: 'none'
    }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto',
      borderRadius: '500px',
      textAlign: 'center'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.primary',
      // backgroundColor: 'transparent',
      // color: 'black',
      textDecorationColor: 'white'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.address, .phone': {
      display: 'none'
    },
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '400',
      '::before': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 1rem 0rem 0rem'
      },
      '::after': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 0rem 0rem 1rem'
      }
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px',
        height: 'auto',
        width: 'auto'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '400',
      '::before': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 1rem 0rem 0rem'
      },
      '::after': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        margin: '0rem 0rem 0rem 1rem'
      }
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ====================================
  // ? =======  Where To Find Us   ========
  // ? ====================================

  whereToFindUsIntro: {
    variant: 'customVariants.sideBySide1',
    height: '75vh',
    '.content': {
      width: ['', '', '35%'],
      '.subtitle': {
        order: '2'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '65%'],
      height: 'fit-content',
      margin: 'auto',
      '.image': {
        objectFit: 'contain'
      }
    }
  },

  // ? ========================
  // ? =======  team   ========
  // ? ========================
  teamMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },

    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  teamPage: {
    '::before': {
      backgroundColor: 'white',
      // height: '150px',
      marginBottom: '2rem'
    },
    '.contentRow': {
      padding: '1rem'
    },
    '.imageContainer': {
      width: ['100%', '100%', '40%', '45%']
    },
    '.contentRow': {
      width: ['100%', '100%', '60%', '55%'],
      padding: ['2rem', '2rem', '3rem', '4rem'],
      '.title': {
        fontSize: ['3rem', '4rem', '5rem']
      },
      '.subtitle': {
        color: 'primary',
        fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
        margin: '2rem 0rem'
      }
    }
  }
}
